<template>
	<div>
		<!-- <meta http-equiv = "refresh" content = "1 ; url = http://lk.vetlight.ru/about/contracts.html"/> -->
		<h2>Список документов</h2>
		<v-list two-line>
			<v-list-item-group v-model="selected" active-class="primary">
				<template v-for="(item, index) in documents">
					<v-list-item @click="redirect(item.url)" :key="item.name">
						<template v-slot:default>
							<v-list-item-content>
								{{ item.name }}
							</v-list-item-content>
						</template>
					</v-list-item>
					<v-divider v-if="index < documents.length - 1" :key="index"></v-divider>
				</template>
			</v-list-item-group>
		</v-list>
	</div>
</template>

<script type="text/javascript">
export default {
	name: 'Documents',
	data() {
		return {
			selected: null,
			documents: [
				{id:1, name:'Договор (оферта) на оказание платных ветеринарных услу', 
					url:'http://lk.vetlight.ru/about/dogovor_oferta.html'},
				{id:2, name:'Приложение 1 (Правила оказания ветеринарной помощи)', 
					url: 'http://lk.vetlight.ru/about/pr01.html'},
				{id:3, name:'Приложение 2 (Анкета владельца пациента)', 
					url:'http://lk.vetlight.ru/about/pr02.html'},
				{id:4, name:'Информированное согласие на размещение животного в стационаре', 
					url:'http://lk.vetlight.ru/about/soglasie_na_stacionar.html'},
				{id:5, name:'Информированное согласие на проведение анестезиологического пособия, хирургического / диагностического вмешательства', 
					url:'http://lk.vetlight.ru/about/soglasit_na_vmeshatelstvo.html'},
				{id:6, name:'Информированный отказ от проведения лечебного / диагностического / анестезиологического вмешательства / стационарного лечения', url: 'http://lk.vetlight.ru/about/otkaz_ot_lecheniya.html'},
				{id:7, name: 'Информированный отказ от утилизации', 
					url:'http://lk.vetlight.ru/about/otkaz_ot_utilizatsii.html'},
				{id:8, name:'Заявление на эвтаназию животного', 
					url:'http://lk.vetlight.ru/about/zayavlenit_na_evtanaziyu.html'},
			],
		}
	},
	methods: {
		redirect(url){
			//console.log(url)
			window.location.href=url
		}
	}
}
</script>